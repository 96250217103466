import Vue from 'vue'

Vue.component('reviews-rating', {
  props: ['id', 'url'],
  data () {
    return {
      contentObserver: null,
      rating: 0,
      reviewCount: 0
    }
  },
  computed: {
    reviewCountText() {
      return `(${this.reviewCount} Review${this.reviewCount > 1 ? 's' : ''})`
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.updateReviewStyle()
    })
  },
  methods: {
    async updateReviewStyle () {
      const reviewsRating = this.$refs.reviewsRating && this.$refs.reviewsRating.length ? this.$refs.reviewsRating[0] : this.$refs.reviewsRating

      if(!reviewsRating || !window.okeWidgetApi) {
        window.setTimeout(this.updateReviewStyle, 500)
        return
      }

      window.okeWidgetApi.setProduct(reviewsRating, `shopify-${this.id}`)

      const starRating = reviewsRating.querySelector('.oke-sr-rating')
      const reviewCount = reviewsRating.querySelector('.oke-sr-count .oke-sr-count-number')

      const changeStarRating = (starRating) => {
        const text = starRating.textContent

        if (text) {
          starRating.textContent = text
          starRating.setAttribute('data-score', text)
        }

        starRating.classList.add('yotpo-review-text')
        starRating.classList.remove('sr-only')
        starRating.setAttribute('aria-label', `Rating score`)
        starRating.setAttribute('aria-describedby', 'rating-desc')

        this.rating = starRating.textContent
      }

      const changeReviewCount = (reviewCount) => {
        const count = parseInt(reviewCount.innerText.replace(/[\.,]/g, ''))
        this.reviewCount = isNaN(count) ? this.reviewCount : count
      }

      if (starRating && reviewCount) {
        if (starRating) {
          changeStarRating(starRating)
        }
        if (reviewCount) {
          changeReviewCount(reviewCount)
        }
      } else {
        const config = { attributes: false, childList: true, subtree: true }
        const callback = (mutations, observer) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'childList') {
              const starRating = reviewsRating.querySelector('.oke-sr-rating')
              const reviewCount = reviewsRating.querySelector('.oke-sr-count .oke-sr-count-number')

              if (starRating || reviewCount) {
                observer.disconnect()
              }

              if (starRating) {
                changeStarRating(starRating)
              }
              if (reviewCount) {
                changeReviewCount(reviewCount)
              }
            }
          })
        }
        this.contentObserver = new MutationObserver(callback)
        this.contentObserver.observe(reviewsRating, config)
      }
    }
  }
})